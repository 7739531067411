/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import './app/shared/styles/form';
@include mat.core();

$custom-primary-palette: (
  50: #f8d0d6,
  100: #f1a5b0,
  200: #e77a8b,
  300: #df4f65,
  400: #d64c60,
  500: #ac3345,
  600: #9a2f40,
  700: #872b3a,
  800: #742736,
  900: #611f31,
  //colori per le tabelle
  1000: #e3e3e3,
  1100: #f2f2f2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  )
);

$primary: mat.m2-define-palette($custom-primary-palette);
$accent: mat.m2-define-palette($custom-primary-palette,500);
$my-app-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $primary,
        accent: $accent,
      ),
    )
);

@include mat.all-component-themes($my-app-theme);

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

*:not(mat-icon):not(span.material-icons) {
  font-family: Poppins !important;
}

table {
  width: 100%;
}

table.mat-mdc-table{
  border: 1px solid  mat.m2-get-color-from-palette($custom-primary-palette, 1000) !important;
}
.mat-mdc-row{
  border-bottom: 1px solid mat.m2-get-color-from-palette($custom-primary-palette, 1000) !important;
  padding-right: 16px;
  padding-left: 16px;
}
.mat-mdc-header-row{
  background-color: mat.m2-get-color-from-palette($custom-primary-palette, 1100) !important;
  padding-left: 16px;
  padding-right: 16px;
}

.mat-mdc-header-cell{
  margin: 0 !important;
}
.mat-mdc-cell{
  margin: 0 !important;
}

table th {
  font-weight: 500 !important;
  color: gray !important;
  font-size: 12px !important;
}
.gray-table-row {
  background-color: mat.m2-get-color-from-palette($custom-primary-palette, 1100) !important;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

th {
  font-weight: bold;
  font-size: 15px;
}

.class-view-container {
  margin-left: 30px;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-mdc-form-field.mat-focused .mat-form-field-label {
  color: #4f4f4f;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.mat-base,
.mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary {
  background-color: mat.m2-get-color-from-palette($custom-primary-palette, 500);
  color: #fff;
}

@import './app/shared/directives/mat-table-responsive/mat-table-responsive';
.alert {
  width: calc(100% - 20px);
  padding: 10px 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #e3e3e3;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #e3e3e3;
}

.alert-warn {
  color: #985f0d;
  background-color: #fcf8e3;
  border-color: #e3e3e3;
}

.form-field-border {
  padding: 10px;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background: mat.m2-get-color-from-palette($custom-primary-palette, 500);;
  color: mat.m2-get-color-from-palette($custom-primary-palette, 500);;
}

.trainee-table {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  border: 1px solid #e3e3e3;
}

.mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background{
  background-color: mat.m2-get-color-from-palette($custom-primary-palette, 500) !important;
  color: #f2dede;
}

.mat-mdc-raised-button .mat-mdc-button-touch-target{
  position: relative !important;
}

.mat-mdc-table {
  box-shadow: none !important;
}

.mdc-evolution-chip-set {
  margin-top:20px !important;
}

.mat-mdc-table,
.mat-mdc-paginator {
  background-color: transparent !important;
}

lib-full-table {
  .mat-toolbar {
    background-color: transparent !important;
  }
}

mat-spinner {
  margin: 40px auto !important;

  svg {
    circle {
      stroke: mat.m2-get-color-from-palette($custom-primary-palette, 500) !important;
    }
  }
}


/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: mat.m2-get-color-from-palette($custom-primary-palette, 500);;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent.mat-radio-checked {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-outer-circle {
    border-color: mat.m2-get-color-from-palette($custom-primary-palette, 500);;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-inner-circle {
    background-color: mat.m2-get-color-from-palette($custom-primary-palette, 500);;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
mat-dialog-container {
  position: relative;
}

table.mat-mdc-table {
  border: 1px solid #e3e3e3;
  border-bottom: none;
  border-radius: 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.mat-chip-list-wrapper {
  margin: 0 !important;
}

lib-full-table {
  .component-content {
    margin: 20px 0 0 0 !important;
  }
}

.red-snackbar {
  background: #a94442 !important;
  color: #f2dede !important;
  button {background: #f2dede; color: #a94442}
}

.mat-focus-indicator.mat-mdc-icon-button.mat-mdc-button-base{
  background: transparent !important;
  color: #f2dede;
}

.filters{
  align-items: initial !important;
}

.popup-close-button{
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;

  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  mat-icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    font-weight: 600;
    margin: 0 !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
}

@media screen and (max-width: 959px){
  .hide-on-mobile{
    display: none !important;
  }
  .hide-on-desktop{
    display: block !important;
  }
}


