
/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-input-element:disabled {
  color: rgba(0, 0, 0, 0.7);
}

.mat-mdc-row.cdk-row.odd,
.mat-mdc-header-row.cdk-header-row {
  background-color: #f6f7f9;
}

.fields-fill {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.larger-form-fields {
  form {
    display: flex;
    flex-wrap: wrap;

    .field {
      width: 100%;

      label {
        height: 40px;
        display: flex;
        align-items: center;
      }
    }

    @media screen and (min-width: 500px) {
      .field:nth-child(odd) {
        margin-right: 10px;
      }

      .field:nth-child(even) {
        margin-left: 10px;
      }

      .field {
        width: calc(50% - 10px);
      }
    }
    
    .field-full {
      width: 100%;
    }
  }

  mat-form-field {
    width: 100%;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-outline {
  background-color: #f6f7f9;
}

form {
  label {
    color: #4f4f4f !important;
    font-weight: 400 !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: rgba(0, 0, 0, .87);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #ac3345;
}